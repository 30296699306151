<template>
  <a-card title="成就">
    <a-row style="padding:30px 0" :gutter="20" type="flex" justify="center" align="middle" class="textal_c">
      <a-col :span="6">
        <h2 class="ay-card-h2">{{achievement.VisitingCount == null ? 0 :achievement.VisitingCount}}</h2>
        <small>访问</small>
      </a-col>
      <a-col :span="6">
        <h2 class="ay-card-h2">{{achievement.NewTotal}}</h2>
        <small>拉新</small>
      </a-col>
      <a-col :span="6">
        <h2 class="ay-card-h2">{{achievement.IntentionTotal}}</h2>
        <small>意向</small>
      </a-col>
      <a-col :span="6">
        <h2 class="textcl_blue ay-card-h2">{{achievement.FinishTotal}}</h2>
        <small>成交</small>
      </a-col>
    </a-row>
  </a-card>
</template> 
<script>
import http from "@/Plugin/Http.js";
export default {
  name: "achievementdetail",
  data() {
    return {
      achievement: [],
      a_partnerID: this.partnerID
    };
  },
  props: {
    //组件属性
    partnerID: String
  },
  methods: {
    //方法
    GetAchievementDetailInfo() {
      var self = this;
      var op = {
        url: "/PartnerModule/PartnerList/AchievementDetailInfo",
        data: {
          partnerID: self.a_partnerID
        },
        OnSuccess: function(res) {
          self.achievement = res.data;
        }
      };
      http.Post(op);
    }
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    this.GetAchievementDetailInfo();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>