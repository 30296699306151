<template>
    <a-card title="人脉">
        <a-row type="flex" align="middle" :gutter="16">
            <a-col :span="4"  style="border-right:1px solid rgba(0,0,0,0.19)">
                <a-col :span="16" class="textal_r" style="padding :0;margin:0">
                        <div>直接下级人数：</div>
                        <br />
                        <br />
                        <div>下级总人数：</div>
                </a-col>
                <a-col :span="8" class="textal_l" style="padding :0;margin:0">
                        <div> {{Contacts.NextCountPersonal}}人</div>
                        <br />
                        <br />
                        <div>{{Contacts.NextCountSum}}人</div>
                </a-col>
            </a-col>
            <a-col :span="8" style="display:flex;">
                <div class="ay-partner-contacts">
                    <div v-for="item in Contacts.contactLists" :key="item.ContactsID">
                        <div class="ay-partner-contacts_img" v-if="!item.isCurrent">
                            <!--<img :src="item.AgentImg" v-if="item.AgentImg!=''" />
                            <img src="/Image/touxiang.svg" v-if="item.AgentImg==''" />-->
                            <img :src="item.AgentImg || '/Image/touxiang.svg'" />
                        </div>
                        <div class="ay-partner-contacts_img" v-if="item.isCurrent">
                            <img :src="item.AgentImg" />
                        </div>
                        <div v-if="item.Level!=9">{{item.Level}}级</div>
                        <div v-if="item.Level==9">{{item.Level}}级以下</div>
                        <div>{{item.AgentValue}}</div>
                    </div>
                </div>
            </a-col>

        </a-row>
    </a-card>
</template>
<script>
    import http from "@/Plugin/Http.js";

    export default {
        name: "contactsdetail",
        data() {
            return {
                Contacts: [],
                a_partnerID: this.partnerID
            }
        },
        props: {//组件属性
            partnerID: String
        },
        methods: {//方法
            GetContactsDetailInfo() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/ContactsDetailInfo",
                    data: {
                        partnerID: self.a_partnerID
                    },
                    OnSuccess: function (res) {
                        self.Contacts = res.data;
                    }
                };
                http.Post(op);
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.GetContactsDetailInfo()
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>