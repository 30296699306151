<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);min-width:max-content">
                    <a-layout style="height:100%;">
                        <a-layout-header style="background:#fff;height:initial;margin-bottom:14px;line-height:inherit;padding:10px 20px;">
                            <userdetail :partnerID="partnerID"></userdetail>
                        </a-layout-header>
                        <a-layout-content>
                            <contactsdetail :partnerID="partnerID"></contactsdetail>
                            <br />
                            <a-row :gutter="16">
                           
                                <a-col :span="12" class="gutter-row">
                                    <div class="gutter-box" style="box-shadow:none">
                                        <taskdetail :partnerID="partnerID" style="display:none"></taskdetail>
                                        
                                        <achievementdetail :partnerID="partnerID"></achievementdetail>
                                    </div>
                                </a-col>
                                     <a-col :span="12" class="gutter-row">
                                    <moneydetail :partnerID="partnerID"></moneydetail>
                                </a-col>
                            </a-row>
                        </a-layout-content>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import userdetail from "./components/userdetail";
    import moneydetail from "./components/moneydetail";
    import taskdetail from "./components/taskdetail";
    import contactsdetail from "./components/contactsdetail";
    import achievementdetail from "./components/achievementdetail";
    export default {
        mame: "PartnerDetail",
        data() {
            return {
                partnerID: ""
            };
        },
        created: function () {
            this.getParams();
        },
        methods: {
            async getParams() {
                this.partnerID = this.$route.query.ID;
            }
        },
        mounted() { },
        components: {
            userdetail,
            moneydetail,
            taskdetail,
            achievementdetail,
            contactsdetail
        }
    };
</script>
