<template>
  <div class="gutter-box" style="box-shadow:none">
    <a-card title="收益(元)">
      <a-row
        :gutter="0"
        type="flex"
     
        align="middle"
        class="textal_c"
        style="padding-top:30px;padding-bottom:30px;"
      >
        <a-col :span="8">
          <h2 class="ay-card-h2">{{Money.Cumulative}}</h2>
          <small>累计金额</small>
        </a-col>
        <a-col :span="8">
            <h2 class="ay-card-h2">{{Money.WithdrawalMoney}}</h2>
          <small>待提现</small>
        </a-col>
              <a-col :span="8">
          <h2 class="ay-card-h2">{{Money.SaleMoney}}</h2>
          <small>销售佣金</small>
        </a-col>
        <!-- <a-col :span="8">
            <h2 class="ay-card-h2">{{Money.ExtensionMoney}}</h2>
          <small>推广佣金</small>
        </a-col> -->
      </a-row>
    <!--    <a-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="textal_c"
        style="padding-top:30px;padding-bottom:30px;"
      >
  
       <a-col :span="8">
            <h2 class="ay-card-h2">{{Money.RewardMoney}}</h2>
          <small>任务奖励</small>
        </a-col> 
      </a-row>-->
    </a-card>
  </div>
</template>
<script>
    import http from "@/Plugin/Http.js";

    export default {
        name: "moneydetail",
        data() {
            return {
                Money: [],
                m_partnerID: this.partnerID
            }
        },
        props: {//组件属性
            partnerID: String
        },
        methods: {//方法
            GetMoneyDetailInfo() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/MoneyDetailInfo",
                    data: {
                        partnerID: self.m_partnerID
                    },
                    OnSuccess: function (res) {
                        res.data.ExtensionMoney = parseFloat(res.data.ExtensionMoney).toFixed(2)
                        res.data.Cumulative= parseFloat(res.data.Cumulative).toFixed(2)
                        res.data.SaleMoney= parseFloat(res.data.SaleMoney).toFixed(2)
                        res.data.RewardMoney= parseFloat(res.data.RewardMoney).toFixed(2)
                        res.data.WithdrawalMoney= parseFloat(res.data.WithdrawalMoney).toFixed(2)

                        self.Money = res.data;
                    }
                };
                http.Post(op);
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.GetMoneyDetailInfo()
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>