<template>
    <div class="yj-list-box">

        <a-row align="middle" type="flex" justify='space-between'>
            <a-col :span="20">
                <div class="yj_lists">
                    <div class="yj_lists_commodity">
                        <div class="yj-partner_list_img">
                            <div>
                                <img :src="User.CurrentAgentImg || '/Image/default-avatar.svg'" />
                            </div>
                        </div>
                        <div class="yj_lists_commodity_text">
                            <div>{{User.CurrentAgentName}}</div>
                            <div>{{User.AgentPhone}}{{User.mobileLocation}}</div>
                            <div>{{User.CheckTime}}加入团队</div>
                            <div v-if="User.RoleNameStrs!=''">
                                <span v-for="role in User.RoleNameStrs" :key="role">{{role}}</span>
                            </div>


                        </div>
                    </div>

                </div>
            </a-col>
            <a-col :span="4">

                <div class="" style="display:flex;justify-content:flex-start;align-items:center;cursor: pointer;" @click="PromoteStimulatePartnerConfig()">
                    <div>
                        <div style="padding:0 10px ">
                            <img :src="Getsrc(User)" style="width:60px;height:60px;object-fit:cover;" />
                        </div>
                    </div>
                    <div class="yj_lists_commodity_text">
                        <div style="cursor:pointer;"> {{User.HonorName}}</div>
                        <div class="star-list">
                            <div class="stars">
                                <div class="stars-f" :title='ToFixed(User)' :style="{'width':(ToFixed(User))+'%'}"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </a-col>
        </a-row>
        <!--<div class="yj_lists_score">
          <div>
            <small>综合评分</small>
          </div>
          <div class="yj_lists_score_bd">{{User.Score}}</div>
        </div>-->
        <!-- <div class="yj_lists_action">
                              雷达图
        </div>-->
    </div>

</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";

    export default {
        name: "userdetail",
        data() {
            return {
                User: [],
                u_partnerID: this.partnerID
            }
        },
        props: {//组件属性
            partnerID: String
        },
        methods: {//方法
            GetUserDetailInfo() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/UserDetailInfo",
                    data: {
                        partnerID: self.u_partnerID
                    },
                    OnSuccess: function (res) {
                        res.data.RoleNameStrs = res.data.RoleNameStrs.split(",");
                        res.data.CheckTime = util.TimeStamptoDateTime(res.data.CheckTime, 'yyyy年MM月dd日')

                        self.User = res.data;
                    }
                };
                http.Post(op);
            },
            Getsrc: function (record) {
                return "/image/honor/" + record.HonorLevel + ".png";
            },
            ToFixed: function (record) {
                return util.ToFixed(record.IntegralBar, "");
            },
            PromoteStimulatePartnerConfig() {
                this.$router.push({
                    name: "PromoteStimulatePartnerConfig_List",
                    query: {
                        PartnerID: this.u_partnerID
                    }
                });
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.GetUserDetailInfo()
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style>
    .stars {
        width: 60px;
        overflow: hidden;
        background: url('/Image/honor/star.svg') repeat-x left center /12px 12px;
        height: 18px;
        position: relative;
    }

    .stars-f {
        background: url('/Image/honor/starfull.svg') repeat-x left center /12px 12px;
        height: 18px;
        left: 0;
        content: '';
        bottom: 0;
        position: absolute;
    }

    .roleTag {
        display: flex;
        flex-direction: row;
    }

    .light-spot {
        margin: 0px;
        padding: 10px 15px;
    }

    .light-spot {
        border: 1px solid #e5e5e5;
        padding: 8px 15px;
        color: #333;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
    }

        .slect, .light-spot:hover {
            color: #32a9ff;
            border-color: #32a9ff;
            background: url("/Image/slctip.svg") no-repeat right 20px /20px 20px;
        }
</style>