<template>
  <a-card title="任务">
    <a-row :gutter="20" type="flex" justify="center" align="middle" class="textal_c">
      <a-col :span="6">
        <h2 class="ay-card-h2">{{Task.Received}}</h2>
        <small>已领取</small>
      </a-col>
      <a-col :span="6">
        <h2 class="ay-card-h2">{{Task.Conducting}}</h2>
        <small>进行中</small>
      </a-col>
      <a-col :span="6">
        <h2 class="ay-card-h2">{{Task.Completed}}</h2>
        <small>已完成</small>
      </a-col>
      <a-col :span="6">
        <h2 class="textcl_red ay-card-h2">{{Task.CumulativeMoney}}</h2>
        <small>累计奖金</small>
      </a-col>
    </a-row>
  </a-card>
</template>
<script>
import http from "@/Plugin/Http.js";

export default {
  name: "taskdetail",
    data() {
            return {
                Task: [],
                t_partnerID: this.partnerID
            }
        },
        props: {//组件属性
            partnerID:String
        },
        methods: {//方法
            GetTaskDetailInfo() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/TaskDetailInfo",
                    data: {
                        partnerID: self.t_partnerID
                    },
                    OnSuccess: function (res) {
                        res.data.CumulativeMoney = parseFloat(res.data.CumulativeMoney).toFixed(2)
                        self.Task = res.data;
                    }
                };
                http.Post(op);
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.GetTaskDetailInfo()
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>